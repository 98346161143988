import { toast, ToastOptions } from 'react-toastify';

import { namedErrors } from '@shared/constants';

const DEFAULT_ERROR_MESSAGE = namedErrors.DEFAULT;

export const normalizeError = (error: unknown): Error => {
  let normalizedErrorMessage;

  if (typeof error === 'string') {
    normalizedErrorMessage = error;
  } else if (typeof error === 'object' && error !== null) {
    if ('message' in error) {
      normalizedErrorMessage = error.message as string;
      //   TODO: come up with a better solution to handle shortMessage to be more prioritized than common message field
    } else if ('shortMessage' in error) {
      normalizedErrorMessage = error.shortMessage as string;
    }
  }

  // Parsing broken errors
  // e.g. '"{'code': 4001, 'message':'User declined request'}"'
  if (normalizedErrorMessage) {
    try {
      const parsedError = JSON.parse(normalizedErrorMessage);

      if (parsedError?.message) {
        normalizedErrorMessage = parsedError.message;
      }
    } catch (parsingError) {
      // nothing
    }
  }

  if (!normalizedErrorMessage) {
    normalizedErrorMessage = DEFAULT_ERROR_MESSAGE;
  }

  return new Error(normalizedErrorMessage);
};

export interface ErrorToastArgs {
  error: unknown;
  replaceErrorMessageCallback?: (errorMessage: string) => {
    newMessage: string | JSX.Element;
    toastOptions?: ToastOptions;
  };
  justReturnHandledMessage?: boolean;
}

const displayErrorToast = ({
  error,
  replaceErrorMessageCallback,
  justReturnHandledMessage,
}: ErrorToastArgs) => {
  let replacedMessage;
  let toastOptions: ToastOptions = {};
  const { message } = normalizeError(error);

  if (replaceErrorMessageCallback) {
    const { newMessage, toastOptions: newToastOptions } = replaceErrorMessageCallback(message);
    replacedMessage = newMessage;

    if (newToastOptions) {
      toastOptions = newToastOptions;
    }
  } else {
    if (message.includes(DEFAULT_ERROR_MESSAGE)) {
      replacedMessage = message;
    } else {
      replacedMessage = `${DEFAULT_ERROR_MESSAGE} ${message}`;
    }
  }

  if (!justReturnHandledMessage) {
    toast.error(replacedMessage, { toastId: message, ...toastOptions });
  }

  return replacedMessage;
};

export default displayErrorToast;
