import Cookies from 'js-cookie';

import storageKeys from '@shared/constants/storageKeys';

const clearSavedConnectionDetails = () => {
  Cookies.remove(storageKeys.MSQ_CONNECTED_WALLET_ADDRESS);
  Cookies.remove(storageKeys.MSQ_CONNECTED_WALLET_TYPE);
};

export default clearSavedConnectionDetails;
