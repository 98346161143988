export const namedWeb3Errors = {
  CONNECTOR_ALREADY_CONNECTED: 'Connector already connected',
  CONNECTOR_NOT_FOUND: 'Connector not found',
  RECONNECT_REQUEST: 'RECONNECT_REQUEST', // NOTE: It's our custom error
  RETRY: 'RETRY', // NOTE: It's our custom error
  REQUEST_CANCELLED: 'User cancelled the request', // NOTE: It's our custom error
  UNKNOWN_TRANSACTION_ERROR: 'Error occurred while trying to make a transaction.', // NOTE: It's our custom error
  TRANSACTION_FAILED: 'The transaction failed to process.', // NOTE: It's our custom error
  INVALID_TOKEN_DATA: 'INVALID_TOKEN_DATA',
  PLEASE_CONNECT_TO_PROCEED: 'Please connect your wallet account', // NOTE: It's our custom error
  EMPTY_ADDRESS: 'Empty address. Please try to reconnect', // NOTE: It's our custom error
  SIGNATURE_VERIFICATION_HAS_FAILED: 'Signature verification has failed, please try once again', // NOTE: It's our custom error
} as const;

const DEFAULT = 'Something went wrong.';
const DEFAULT_RETRY = `${DEFAULT} Please try again later`;

export const namedErrors = {
  DEFAULT,
  DEFAULT_RETRY,
  DEFAULT_RETRY_OR_CONTACT_SUPPORT: `${DEFAULT_RETRY} or contact support.`,
  REFLECT_GET_CALLED_ON_NON_OBJECT: 'Reflect.get called on non-object',
  RESOURCE_UNAVAILABLE: 'Resource unavailable',
  RESOURCE_UNAVAILABLE2: 'Requested resource not available',
  AN_INTERNAL_ERROR_HAS_OCCURRED: 'An internal error has occurred',
  INVALID_EMAIL_ADDRESS: 'Invalid Email Address', // NOTE: It's our custom error
  WALLET_HAS_GITCOIN_SCORE:
    'This wallet cannot be linked to this account because it has already earned a Gitcoin score with a different account.', // NOTE: It's our custom error
} as const;

export const replaceErrorMessageMap = {
  TooLateToSaleGoalReached: { TooLate: 'The sale goal has been reached' },
} as const;
