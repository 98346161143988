/**
 * Stores reusable global storage keys
 */
const storageKeys = {
  MSQ_CONNECTED_WALLET_ADDRESS: 'MSQ_CONNECTED_WALLET_ADDRESS', // Stores currently connected wallet address. Goes together with MSQ_CONNECTED_WALLET_TYPE
  MSQ_CONNECTED_WALLET_TYPE: 'MSQ_CONNECTED_WALLET_TYPE', // typeof WalletType. Stores currently connected wallet provider type. Goes together with MSQ_CONNECTED_WALLET_ADDRESS
  REFERRAL: 'referral', // Stores referral link
  MAGIC_BOOST_CONFIRMED_VIA: 'MAGIC_BOOST_CONFIRMED_VIA',
  LOGGED_INTO_MAGIC_BOOST: 'LOGGED_INTO_MAGIC_BOOST',
  PREVENT_LOG_OUT_ON_ACCOUNT_CHANGE: 'PREVENT_LOG_OUT_ON_ACCOUNT_CHANGE', // If enabled then no logout on account change will be triggered
  SELF_SWAP_TERMS_AGREEMENT: 'ssta',
} as const;

export default storageKeys;
