import Cookies from 'js-cookie';
import { Options } from 'ky';
import { KyHeadersInit } from 'ky/distribution/types/options';

import { getProcessEnvGlobalConfig } from '../config/global';

export const captchaContainerName = 'turnstile-container';

export const getTurnstileChallenge = (action = 'unnamed_action') => {
  const siteKey = process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY;
  const captchaSelector = `#${captchaContainerName}`;

  return new Promise((resolve, reject) => {
    if (!window.turnstile) {
      reject(new Error('Turnstile is not initialized. Please contact support.'));
    }

    return window.turnstile.ready(() => {
      window.turnstile.render(captchaSelector, {
        action,
        sitekey: siteKey,
        callback(token: string) {
          if (token) {
            resolve(token);
          } else {
            reject(new Error('No token received after Turnstile execution.'));
          }
        },
      });
    });
  });
};

// Note: Expand this function if there are more than one captcha
const getIsCaptchaEnabled = () => {
  return process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_CAPTCHA_ENABLED === 'true';
};

// Note: Expand this function if there are more than one captcha
const getCaptchaToken = async (actionName: string) => {
  const headerName = 'x-cf-token';
  const token = await getTurnstileChallenge(actionName);

  return { headerName, token };
};

export const captchaDecorator =
  <TApiOptions extends Options = Options, TReturn = any>(
    apiCallback: (options: TApiOptions) => Promise<TReturn>,
    actionName: string,
    alwaysEnabled?: boolean,
  ) =>
  async (options: TApiOptions): Promise<TReturn> => {
    const requestOptions: TApiOptions = options || ({} as TApiOptions);
    const isEnabled = getIsCaptchaEnabled();

    if (!isEnabled || !alwaysEnabled) {
      return apiCallback(requestOptions);
    }

    const isDev = getProcessEnvGlobalConfig('isDev');
    const isLocalEnv = getProcessEnvGlobalConfig('isLocalEnv');

    const hasSkipCookieOnDev = isDev && Cookies.get('cf-skip-cap');

    if (hasSkipCookieOnDev || isLocalEnv) {
      requestOptions.headers = {
        ...requestOptions.headers,
        // NOTE: Random value. Needed to be able to login on DEV environment. Only works on DEV environment
        'cf-skip-turnstile': 'rusik-TRUSIK.lol.captcha-DISABLER-23423423',
      } as KyHeadersInit;

      return apiCallback(requestOptions);
    }

    const { headerName, token } = await getCaptchaToken(actionName);

    if (token) {
      requestOptions.headers = {
        ...requestOptions.headers,
        [headerName]: token,
      } as KyHeadersInit;

      return apiCallback(requestOptions);
    }

    return Promise.reject(
      new Error('Captcha challenge failed. Try again or contact site admin for help.'),
    );
  };
