import { ComponentType } from 'react';

import CircleEmail from '@uikit/icons/CircleEmail';
import CircleLinkedIn from '@uikit/icons/CircleLinkedIn';
import CircleReddit from '@uikit/icons/CircleReddit';
import CircleTelegram from '@uikit/icons/CircleTelegram';
import CircleTwitter from '@uikit/icons/CircleTwitter';
import Facebook from '@uikit/icons/Facebook';

export const shareSocials: { name: string; Icon: ComponentType; color: string }[] = [
  {
    name: 'Facebook',
    Icon: Facebook,
    color: '#1877F2',
  },
  {
    name: 'Twitter',
    Icon: CircleTwitter,
    color: '#1D9BF0',
  },
  {
    name: 'LinkedIn',
    Icon: CircleLinkedIn,
    color: '#0A66C2',
  },
  {
    name: 'Telegram',
    Icon: CircleTelegram,
    color: '#1D9BF0',
  },
  {
    name: 'Reddit',
    Icon: CircleReddit,
    color: '#FF4500',
  },
  {
    name: 'Email',
    Icon: CircleEmail,
    color: '#838383',
  },
];

export const DISCORD_INVITE_URL = 'https://discord.com/invite/magicsquareio';

export const DISCORD_URL = 'https://discord.gg/magicsquareio';

export const GITBOOK_URL = 'https://docs.magic.store';
