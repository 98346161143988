import { HTTPError } from 'ky';

import { isPathMatches } from '@shared/helpers/url';

import { ApiError } from './api';
import getApiHost from './getApiHost';

const urlsFor404: string[] = [
  '/v2/store/page',
  '/v2/store/app',
  '/v1/main/store/app',
  '/v2/hot-offers/how-to-get/:offerId',
  '/v1/main/store/see-all',
  '/v2/store/creator',
];

const host = getApiHost();

export const isNeedRedirect = (error: HTTPError) => {
  if (error.response.status !== 404) {
    return false;
  }

  const apiPathname = new URL(host).pathname;
  const responsePathname = new URL(error.response.url).pathname;
  const normalizedResponsePathname = responsePathname.replace(apiPathname, '');
  const is404Path = urlsFor404.some((url404) => isPathMatches(url404, normalizedResponsePathname));

  return is404Path;
};

type ApiErrorHandler = (httpError: HTTPError, apiError: ApiError) => void;

export const serverError = () => null;

export const getAction = ({ code, message }: ApiError): ApiErrorHandler => {
  if (
    ([401].includes(code) || message === 'INVALID_TOKEN') &&
    typeof window?.__logoutClient === 'function'
  ) {
    // TODO: quick fix to prevent shared lib depend on store-web
    return window?.__logoutClient;
  }

  return serverError;
};

/**
 * @param {import('ky').HTTPError} httpError
 * @param {{
 *  message: string;
 *  code: number;
 *  type: string;
 * }} apiError
 */
export const handleApiError: ApiErrorHandler = async (httpError, apiError) => {
  const action = getAction(apiError);

  action(httpError, apiError);
};
