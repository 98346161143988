import { HTTPError, Options } from 'ky';
import { isObject } from 'lodash';

export interface ApiErrorObject {
  message: string;
  code: number;
  type: string;
  data?: any;
}

export class ApiError extends Error implements ApiErrorObject {
  constructor(
    public override message: string,
    public code: number,
    public type: string,
    public data?: any,
    public raw?: any,
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

export type ApiOptions<PartialOptions extends Partial<Options> = Options> = Omit<
  Options,
  keyof PartialOptions
> &
  PartialOptions;

export const getApiError = async (error: HTTPError): Promise<ApiError> => {
  let apiErrorData: ApiErrorObject = {
    type: 'UNKNOWN_TYPE',
    code: error.response.status,
    message: error.message,
  };

  try {
    const errorResponse: any = await error.response.json();

    // Handle JSONAPI errors array.
    if (
      errorResponse?.errors &&
      Array.isArray(errorResponse?.errors) &&
      !!errorResponse?.errors[0]
    ) {
      apiErrorData = {
        ...apiErrorData,
        ...errorResponse.errors[0],
        message: errorResponse.errors[0].message || errorResponse.errors[0].title || '',
      };
    } else {
      apiErrorData = {
        ...apiErrorData,
        ...errorResponse,
      };
    }
  } catch (e) {
    // Do nothing
  }

  return new ApiError(
    apiErrorData.message,
    apiErrorData.code,
    apiErrorData.type,
    apiErrorData.data,
    apiErrorData,
  );
};

export const isApiError = (error: unknown): error is ApiError => {
  return isObject(error) && 'message' in error && 'code' in error && 'type' in error;
};
