export const PROJECT_NAMES = {
  STAKING: 'staking',
  STORE: 'store-web',
  LAUNCHPAD: 'launchpad',
  SWAP: 'swap',
} as const;

export type ProjectName = (typeof PROJECT_NAMES)[keyof typeof PROJECT_NAMES];

export const HOSTS = {
  [PROJECT_NAMES.STAKING]: process.env.NEXT_PUBLIC_STAKING_HOST,
  [PROJECT_NAMES.STORE]: process.env.NEXT_PUBLIC_STORE_HOST,
  [PROJECT_NAMES.LAUNCHPAD]: process.env.NEXT_PUBLIC_LAUNCHPAD_HOST,
  [PROJECT_NAMES.SWAP]: process.env.NEXT_PUBLIC_SWAP_HOST,
};

export const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME as ProjectName;

export const IS_STORE = PROJECT_NAME === PROJECT_NAMES.STORE;
