const getApiHost = () => {
  if (typeof process !== 'undefined' && process?.env?.NEXT_PUBLIC_API_HOST) {
    return process.env.NEXT_PUBLIC_API_HOST;
  }

  if (import.meta && import.meta?.env.VITE_API_URL) {
    return import.meta.env.VITE_API_URL;
  }

  return '';
};

export default getApiHost;
