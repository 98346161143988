export const MIN_KARMA: { referralLink: number } = {
  referralLink: 50,
};

export const KARMA_ACTION_FREQUENCY = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const KARMA_HISTORY_PERIOD = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  WEEK: 'week',
  MONTH: 'month',
  DATE: 'date',
  TOTAL: 'total',
};
